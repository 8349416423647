/*
 * @Descripttion: 
 * @version: 
 * @Author: ZCP
 * @Date: 2021-05-16 23:09:12
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  }, 
   {
    path: '/brand',
    name: 'brand',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/brand.vue')
  },
  {
    path: '/user/:id',
    name: 'user',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/user.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base:'index.html',
  routes
})

export default router
